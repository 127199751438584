import { graphql } from "gatsby"
import React from "react"
import Page from "../components/page"
import RequestPricingTemplate from "../components/request-pricing-template"

const RequestPricingPage = ({
  data: { footerData, requestPricingPageData },
}) => {
  return (
    <Page headerData={{ frontmatter: {} }} footerData={footerData}>
      <RequestPricingTemplate data={requestPricingPageData.frontmatter} />
    </Page>
  )
}

export default RequestPricingPage

export const pageQuery = graphql`
  query RequestPricingQuery {
    ...PageFragment
    requestPricingPageData: markdownRemark(
      fileAbsolutePath: { regex: "/pages/request-pricing.md/" }
    ) {
      frontmatter {
        title
      }
    }
  }
`
